<template>
  <v-card @click="goToDetails" style="width: 100%">
    <v-card-text class="d-flex flex-row mt-3" style="width: 100%">
      <div>
        <v-img
          :src="require('@/assets/svg/awards.svg')"
          width="30"
          height="30"
          contain
        />
      </div>
      <div class="d-flex flex-column mx-3" style="flex-grow:1">
        <span style="font-size: 16px; font-family: 'Poppins-SemiBold';">
          {{ award.title }}
        </span>
        <div
          style="font-size: 12px;font-family: 'Poppins-Regular';"
          class="d-flex"
        >
          <div
            class="url-button"
            @click.stop="goToAgencyProfile(award.sponsor)"
            @mousedown.stop
            @touchstart.stop
          >
            {{ award.sponsor_name }}
          </div>
        </div>
        <span
          style="color: #5b2cae; font-size: 12px;font-family: 'Poppins-Regular';"
        >
          {{ formatDate(award.offer_date) }}
        </span>
        <span> Goal: {{ award.goal }} hrs </span>
        <div>
          Items:
          <span
            style="font-size: 12px;font-family: 'Poppins-Regular';"
            v-for="item in award.items"
            :key="item.name"
          >
            {{ item.name }}{{ item.unit ? `(${item.unit}),` : "" }}
          </span>
        </div>
        <div>
          Sponsor:
          <span
            :class="{ 'url-button': award.sponsor_type != 'Admin' }"
            @click.stop="goToAgencyProfile(award.sponsor)"
            @mousedown.stop
            @touchstart.stop
            >{{ award.sponsor_name }}</span
          >
        </div>
      </div>
      <div style="flex-shrink: 0" v-if="!hideButtons">
        <v-tooltip bottom v-if="profile._id == award.sponsor">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
              small
              @click.stop="onEdit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                >mdi-pencil</v-icon
              >
            </v-btn>
          </template>
          Edit Award
        </v-tooltip>
        <v-tooltip bottom v-if="profile._id == award.sponsor">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
              small
              class="ml-2"
              @click.stop="onDelete"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                >mdi-delete</v-icon
              >
            </v-btn>
          </template>
          Delete Award
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-text class="mt-0 pt-0">
      <span
        class="text-block"
        v-if="text_collaped"
        v-linkified
        v-text="collaped_text"
      />
      <span v-else v-text="award.description" class="text-block" v-linkified />
      <span
        class="url-button"
        @click.stop="text_collaped = false"
        v-if="text_collaped"
        x-small
        style="position: absolute; right: 18px; bottom: 0; background: white"
      >
        ...see more
      </span>
      <div v-if="users.length > 0 && profileType != 'Volunteer'" class="mt-2">
        Eligible users
        <div v-for="user in users" :key="user._id" class="my-4">
          <editable-avatar
            :image="user.thumbnail ? user.thumbnail : user.image"
            :editable="false"
            :size="30"
            class="ml-2"
          />
          <span
            class="ml-2"
            style="font-family: 'Poppins-SemiBold'; color: #242f36"
          >
            {{ user.first_name }} {{ user.last_name }} -
            {{ formatMinutes(user.minutes) }} hrs
          </span>
        </div>
      </div>
      <div
        v-else-if="users.length == 0 && profileType != 'Volunteer'"
        class="mt-5"
      >
        No eligible volunteers
      </div>
      <div v-if="profileType == 'Volunteer'" class="mt-2">
        <span style="font-family: 'Poppins-SemiBold';">My hours:</span>
        {{ formatMinutes(myHours) }} hrs
      </div>
      <div
        v-if="
          myHours >= parseInt(award.goal) * 60 && profileType == 'Volunteer'
        "
        style="font-family: 'Poppins-SemiBold';"
      >
        Eligible
      </div>
      <div
        v-else-if="profileType == 'Volunteer'"
        style="font-family: 'Poppins-SemiBold';"
      >
        Not eligible
      </div>
    </v-card-text>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
    <edit-awards
      :dialog="editDialog"
      :onClose="onCloseDialog"
      :item="award"
      v-if="editDialog"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onDeleteAward"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import EditAwards from "./EditAwards.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import EditableAvatar from "../../components/EditableAvatar.vue";
export default {
  components: { EditAwards, ConfirmDeleteDialog, EditableAvatar },
  props: {
    award: Object,
    hideButtons: {
      type: Boolean,
      default: false,
    },
    onClicked: Function,
    eligibles: Array,
  },
  data() {
    return {
      snackbar: false,
      snackMessage: null,
      text_collaped: true,
      collaped_text: "",
      editDialog: false,
      deleteDialog: false,
      users: [],
      myHours: 0,
    };
  },
  methods: {
    ...mapActions("awards", {
      deleteAward: "deleteAward",
      getVolunteerHours: "getVolunteerHours",
    }),
    goToDetails() {
      // if (!this.onClicked) {
      //   this.$router.push({
      //     name: "agency-need-details",
      //     query: {
      //       _id: this.award._id,
      //     },
      //   });
      // } else {
      //   this.onClicked(this.award._id);
      // }
    },
    goToAgencyProfile(agency) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: agency },
      });
    },
    getCollapedText() {
      if (!this.award.description) {
        this.text_collaped = false;
        return;
      }
      var textArray = this.award.description.split(/\r\n|\r|\n/);
      var text1 = "";
      for (var i = 0; i < textArray.length; i++) {
        if (i >= 3) break;
        text1 += textArray[i] + "\n";
      }
      text1 = text1.trim();
      var text2 = this.award.description.substring(0, 300);
      text2 = text2.trim();
      if (text1.length < text2.length) {
        this.collaped_text = text1;
      } else {
        this.collaped_text = text2;
      }
      if (this.collaped_text.length == this.award.description.length) {
        this.text_collaped = false;
      } else {
        this.text_collaped = true;
      }
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    onEdit() {
      this.editDialog = true;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
      this.editDialog = false;
    },
    onDeleteAward() {
      this.deleteAward({ _id: this.award._id }).catch((error) => {
        console.log(error);
      });
    },
    formatMinutes(value) {
      var hrs = Math.floor(value / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = Math.floor(value % 60);
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  watch: {
    eligibles(newValue) {
      this.users = newValue.filter((e) => {
        e.minutes >= parseInt(`${this.award.goal}`) * 60;
      });
    },
  },
  mounted() {
    this.getCollapedText();
    this.users = this.eligibles.filter((e) => {
      e.minutes >= parseInt(`${this.award.goal}`) * 60;
    });
    if (this.profileType == "Volunteer") {
      this.getVolunteerHours({
        sponsor: this.award.sponsor,
        sponsor_type: this.award.sponsor_type,
      })
        .then((res) => {
          if (res.length != 0) {
            this.myHours = res[0].minutes;
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  },
};
</script>
